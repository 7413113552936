import React, { PureComponent } from "react";
import {
  FaInfoCircle,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
} from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import cx from "classnames";
import { Link } from "gatsby";

import CaCircle from "../../img/cacircle.png";

import styles from "./Footer.module.scss";

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showingFooter: false,
    };
  }

  toggleFooterState = () => {
    this.setState((prevState) => ({
      showingFooter: !prevState.showingFooter,
    }));
  };

  render() {
    const { showingFooter } = this.state;
    const { goTo } = this.props;
    return (
      <React.Fragment>
        <div
          className={cx(styles.Footer, {
            [styles.Visible]: showingFooter,
          })}
        >
          <IoIosCloseCircle
            className={styles.FooterIcon}
            onClick={this.toggleFooterState}
          />
          <div className={styles.FooterContent}>
            <div>
              <div className={styles.CaIconBlock}>
                <img src={CaCircle} />
              </div>
              <div className={styles.MainDescription}>
                <h3>Where will great design take you?</h3>
                <p>
                  Casi Ari is a brand and design agency focused on creating
                  identity systems for visionary businesses.
                  <br />
                  <a
                    onClick={() => {
                      goTo("contact");
                      this.toggleFooterState();
                    }}
                    className={styles.LetsBeginLink}
                  >
                    Let's Begin.
                  </a>
                </p>
                <p className={styles.Copyright}>
                  Copyright &copy; Casi Ari, LLC 2020
                </p>
              </div>
              <div className={styles.FooterMenu}>
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        goTo("");
                        this.toggleFooterState();
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        goTo("approach");
                        this.toggleFooterState();
                      }}
                    >
                      Approach
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        goTo("services");
                        this.toggleFooterState();
                      }}
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        goTo("work");
                        this.toggleFooterState();
                      }}
                    >
                      Work
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        goTo("contact");
                        this.toggleFooterState();
                      }}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/giveaway">2020 Brand Giveaway</Link>
                  </li>
                  <li>
                    <Link to="/referral">Refer a Friend</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {!showingFooter && (
          <FaInfoCircle
            className={styles.FooterIcon}
            onClick={this.toggleFooterState}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Footer;
