import React, { PureComponent } from "react";
import cx from "classnames";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
} from "react-icons/fa";

import CaCircle from "../../img/cacircle.png";

import styles from "./Navbar.module.scss";
import { Link } from "gatsby";

const renderDarkNavItems = (path) => {
  return path !== "";
};
const renderDarkLogo = (path) => {
  return (
    path === "work" ||
    path === "team-" ||
    path === "team-#dani" ||
    path === "terms" ||
    path === "404" ||
    path === "clients"
  );
};
const renderDarkCollapsedMenuButton = (path) => {
  return (
    path === "work" ||
    path === "team-" ||
    path === "terms" ||
    path === "about" ||
    path === "team" ||
    path === "404" ||
    path === "clients"
  );
};

export default class Navbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMiniNav: false,
    };
  }

  toggleMiniNav = () => {
    this.setState((prevState) => ({ showMiniNav: !prevState.showMiniNav }));
  };

  render() {
    const { goTo, coverWhite, coverWhiteNah } = this.props;
    const { showMiniNav } = this.state;
    const path = this.props.pathname.replace(/^\/|\/$/g, "");
    const darkNavItems = renderDarkNavItems(path);
    const darkLogo = renderDarkLogo(path);
    const darkMenuButton = renderDarkCollapsedMenuButton(path);
    return (
      <React.Fragment>
        <nav
          className={cx(styles.Navbar, {
            [styles.Dark]: darkNavItems,
            [styles.WhiteBG]: coverWhite,
            [styles.WhiteForce]: coverWhiteNah,
          })}
        >
          <ul>
            <li
              className={cx(styles.Logo, {
                [styles.DarkLogo]: darkLogo,
              })}
            >
              <a onClick={() => goTo("")} className={styles.LogoLink}>
                <div className={styles.LogoDiv} />
              </a>
            </li>
          </ul>
          <ul className={styles.RightItems}>
            <li>
              <a
                onClick={() => goTo("approach")}
                className={cx({ [styles.ActivePage]: path === "approach" })}
              >
                Approach
              </a>
            </li>
            <li>
              <a
                onClick={() => goTo("services")}
                className={cx({ [styles.ActivePage]: path === "services" })}
              >
                Services
              </a>
            </li>
            <li>
              <a
                onClick={() => goTo("work")}
                className={cx({ [styles.ActivePage]: path === "work" })}
              >
                Our Work
              </a>
            </li>
            <li>
              <a onClick={() => goTo("contact")} className={styles.ContactBtn}>
                Start a Project
              </a>
            </li>
          </ul>
        </nav>
        <div
          className={cx(styles.CollapsedMenu, {
            [styles.DarkMenuButton]:
              (darkMenuButton || showMiniNav) && !coverWhiteNah,
          })}
        >
          <div
            className={cx(styles.CustomMenuWrapper, {
              [styles.OpenMenu]: showMiniNav,
            })}
          >
            <div
              className={cx(styles.CustomMenu, {
                [styles.OpenMenu]: showMiniNav,
              })}
              onClick={this.toggleMiniNav}
            >
              <div className={cx(styles.LineMenu, styles.Half, styles.Start)} />
              <div className={styles.LineMenu} />
              <div className={cx(styles.LineMenu, styles.Half, styles.End)} />
            </div>
          </div>
          <div
            className={cx(styles.CollapsedNav, {
              [styles.Hidden]: !showMiniNav,
            })}
          >
            <div className={styles.CollapsedNavContent}>
              <div>
                <div className={styles.CaIconBlock}>
                  <div>
                    <img src={CaCircle} />
                  </div>
                  <div>
                    <div>
                      <h3>Where will great</h3>
                      <h3>design take you?</h3>
                    </div>
                    <div className={styles.MainDescription}>
                      <p>
                        Casi Ari is a brand and design agency focused on
                        creating identity systems for visionary businesses.
                        <br />
                        <a
                          onClick={() => {
                            goTo("contact");
                            this.toggleMiniNav();
                          }}
                          className={styles.LetsBeginLink}
                        >
                          Let's Begin.
                        </a>
                      </p>
                      <ul>
                        <li>
                          <a
                            onClick={() => {
                              goTo("");
                              this.toggleMiniNav();
                            }}
                          >
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              goTo("approach");
                              this.toggleMiniNav();
                            }}
                          >
                            Approach
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              goTo("services");
                              this.toggleMiniNav();
                            }}
                          >
                            Services
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              goTo("work");
                              this.toggleMiniNav();
                            }}
                          >
                            Our Work
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              goTo("contact");
                              this.toggleMiniNav();
                            }}
                          >
                            Start a Project
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link
                            to="/about"
                            onClick={() => {
                              this.toggleMiniNav();
                            }}
                          >
                            About
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/giveaway"
                            onClick={() => {
                              this.toggleMiniNav();
                            }}
                          >
                            2020 Brand Giveaway
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/referral"
                            onClick={() => {
                              this.toggleMiniNav();
                            }}
                          >
                            Refer a Friend
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/terms"
                            onClick={() => {
                              this.toggleMiniNav();
                            }}
                          >
                            Terms and Conditions
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/privacy-policy"
                            onClick={() => {
                              this.toggleMiniNav();
                            }}
                          >
                            Privacy Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.Copyright}>
                      <p>Copyright &copy; Casi Ari, LLC 2020</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
